import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useAppointment = ({
   appointmentId,
}: {
   appointmentId?: string;
}) => {
   const { data: appointment, ...rest } = useQuery({
      queryKey: queryKeys.appointment(appointmentId),
      queryFn: async () => {
         if (!appointmentId) {
            return Promise.resolve(undefined);
         }

         const response = await webApiClient.appointments.getWithTimetap({
            params: {
               id: appointmentId,
            },
         });

         return response.body;
      },
      enabled: Boolean(appointmentId),
   });

   return {
      appointment: appointment ?? null,
      ...rest,
   };
};
